import 'react-tabs/style/react-tabs.css'
import 'leaflet/dist/leaflet.css'

import styled from '@emotion/styled'
import { FadeInOutAnimation } from 'app/components/Common/FadeInOutAnimation'
import { Image } from 'app/components/Common/Image'
import { FlexBox } from 'app/components/Layout/FlexBox'
import L from 'leaflet'
import React, { memo, useEffect, useState } from 'react'
import LazyLoad from 'react-lazyload'
import { MapContainer, Marker, Popup, TileLayer } from 'react-leaflet'
import { Tab, TabList, TabPanel, Tabs } from 'react-tabs'

export interface Props {
  directions?: { [key: string]: any }
  image?: { [key: string]: any }
  latitude?: string
  longitude?: string
  siteName?: string
  title?: string
  zoom?: string
}

export const Directions = memo(function Directions({
  directions,
  image,
  latitude,
  longitude,
  siteName,
  title,
  zoom,
}: Props) {
  const [markerIcon, setMarkerIcon] = useState(L.divIcon)

  useEffect(() => {
    setMarkerIcon(
      L.divIcon({
        iconSize: [32, 32],
        iconAnchor: [16, 32],
        html: '<svg version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0" y="0" viewBox="0 0 512 512" style="enable-background:new 0 0 512 512;" xml:space="preserve"><path d="M256,0C153.755,0,70.573,83.182,70.573,185.426c0,126.888,165.939,313.167,173.004,321.035 c6.636,7.391,18.222,7.378,24.846,0c7.065-7.868,173.004-194.147,173.004-321.035C441.425,83.182,358.244,0,256,0z M256,278.719 c-51.442,0-93.292-41.851-93.292-93.293S204.559,92.134,256,92.134s93.291,41.851,93.291,93.293S307.441,278.719,256,278.719z"/></svg>',
      }),
    )
  }, [])

  return (
    <Container>
      {title ? (
        <FadeInOutAnimation>
          <Title>{title}</Title>
        </FadeInOutAnimation>
      ) : null}
      <FadeInOutAnimation>
        <Wrapper row space="between" stretch wrap>
          <HowToReach>
            {image ? (
              <LazyLoad>
                <Image {...image} />
              </LazyLoad>
            ) : null}
            {directions ? (
              <TextWrap>
                <Tabs>
                  <TabList>
                    {directions.map((item: any, index: number) => (
                      <Tab key={index}>{item.title}</Tab>
                    ))}
                  </TabList>

                  {directions.map((item: any, index: number) => (
                    <TabPanel
                      key={index}
                      dangerouslySetInnerHTML={{ __html: item.description }}
                    />
                  ))}
                </Tabs>
              </TextWrap>
            ) : null}
          </HowToReach>
          <Map>
            {typeof window !== undefined && latitude && longitude ? (
              <MapContainer
                center={[Number(latitude), Number(longitude)]}
                dragging={L.Browser && L.Browser.mobile ? false : true}
                zoom={Number(zoom)}
                scrollWheelZoom={false}
              >
                <TileLayer
                  attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
                  url="https://{s}.basemaps.cartocdn.com/light_all/{z}/{x}/{y}{r}.png"
                />
                <Marker
                  icon={markerIcon}
                  position={[Number(latitude), Number(longitude)]}
                >
                  {siteName ? <Popup>{siteName}</Popup> : null}
                </Marker>
              </MapContainer>
            ) : null}
          </Map>
        </Wrapper>
      </FadeInOutAnimation>
    </Container>
  )
})

const Container = styled.section`
  margin: 9.25rem auto;

  @media (max-width: 1199px) {
    margin: 7.5rem auto;
  }

  @media (max-width: 767px) {
    margin: 5.25rem auto;
  }
`

const Title = styled.h3`
  display: flex;
  align-items: center;
  flex-direction: row;
  color: ${({ theme }) => theme.colors.variants.neutralDark1};
  font-family: ${({ theme }) => theme.fontFamily.heading};
  font-size: 2.625rem;
  line-height: 3.375rem;
  margin-bottom: 1.5rem;
  &:before,
  &:after {
    content: '';
    flex: 1 1;
    background: ${({ theme }) => theme.colors.variants.neutralLight1};
    height: 2px;
  }
  &:before {
    margin-right: 1.5rem;
  }
  &:after {
    margin-left: 1.5rem;
  }
`

const Wrapper = styled(FlexBox)`
  margin-top: 3.75rem;
`

const HowToReach = styled.div`
  width: calc(50% - 1px);
  font-family: ${({ theme }) => theme.fontFamily.paragraph};
  overflow: hidden;
  position: relative;
  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    position: absolute;
    top: 0;
    left: 0;
  }

  .react-tabs {
    padding: 1.5rem 3.75rem 3.75rem;
    .react-tabs__tab-list {
      border: 0;
      text-align: center;
    }
    .react-tabs__tab {
      border: 0;
      color: ${({ theme }) => theme.colors.variants.neutralLight2};
      font-size: 0.8125rem;
      font-weight: 700;
      letter-spacing: 1px;
      line-height: 1rem;
      margin: 1.5rem 1.5rem 0;
      padding: 0;
      text-transform: uppercase;
      transition: 0.3s ease-out;
      &:focus {
        box-shadow: none;
      }
      &:hover {
        color: ${({ theme }) => theme.colors.variants.primaryDark1};
      }
      &.react-tabs__tab--selected {
        color: ${({ theme }) => theme.colors.variants.neutralDark1};
        border-radius: 0;
      }
    }
    .react-tabs__tab-panel {
      color: ${({ theme }) => theme.colors.variants.neutralDark3};
      font-size: 0.9375rem;
      line-height: 1.875rem;
      margin-top: 3rem;
      &.react-tabs__tab-panel--selected {
        animation: fadeInPanel 0.6s;
      }
    }
  }

  @keyframes fadeInPanel {
    from {
      opacity: 0;
      transform: translateY(2.25rem);
    }
    to {
      opacity: 1;
      transform: translateY(0);
    }
  }

  @media (max-width: 1199px) {
    .react-tabs {
      padding: 0.5rem 1.5rem 3rem;
    }
  }

  @media (max-width: 767px) {
    width: 100%;
    order: 2;
    img {
      display: none;
    }
  }

  @media (max-width: 374px) {
    .react-tabs {
      .react-tabs__tab {
        margin-right: 0.75rem;
        margin-left: 0.75rem;
      }
    }
  }
`

const TextWrap = styled.div`
  width: calc(100% - 7.5rem);
  height: calc(100% - 7.5rem);
  background: ${({ theme }) => theme.colors.variants.neutralLight4};
  margin: 3.75rem;
  position: relative;

  @media (max-width: 1199px) {
    width: calc(100% - 3rem);
    height: calc(100% - 3rem);
    margin: 1.5rem;
  }

  @media (max-width: 767px) {
    width: 100%;
    height: 100%;
    margin: 0;
  }
`

const Map = styled.div`
  width: calc(50% - 1px);
  background: ${({ theme }) => theme.colors.variants.neutralLight3};
  padding-bottom: 35%;
  position: relative;

  .leaflet-container {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
  }
  .leaflet-marker-icon {
    background: none;
    border: 0;
    svg {
      fill: ${({ theme }) => theme.colors.variants.primaryDark1};
    }
  }
  .leaflet-left {
    top: auto;
    right: 0;
    bottom: 0;
    left: auto;
    .leaflet-control {
      display: flex;
      border: 0;
      border-radius: 0;
      margin: 0;
      a {
        width: 2.5rem;
        height: 2.5rem;
        line-height: 2.5rem;
        background: ${({ theme }) => theme.colors.variants.neutralLight4};
        color: ${({ theme }) => theme.colors.variants.neutralDark1};
        font-family: ${({ theme }) => theme.fontFamily.paragraph};
        font-weight: 100;
        font-size: 1.875rem;
        border: 0;
        border-radius: 0;
        transition: 0.3s ease-out;
        &.leaflet-control-zoom-in {
          order: 2;
        }
        &.leaflet-disabled {
          opacity: 0.6;
          pointer-events: none;
        }
        &:hover {
          background: ${({ theme }) => theme.colors.variants.neutralLight1};
        }
      }
    }
  }
  .leaflet-bottom {
    display: none;
  }
  .leaflet-popup {
    .leaflet-popup-content-wrapper {
      background: ${({ theme }) => theme.colors.variants.primaryDark1};
      box-shadow: none;
      border-radius: 0;
      color: ${({ theme }) => theme.colors.variants.neutralLight4};
      font-family: ${({ theme }) => theme.fontFamily.heading};
      font-size: 1rem;
      padding: 1rem;
    }
    .leaflet-popup-content {
      line-height: 1.5rem;
      margin: 0;
    }
    .leaflet-popup-tip {
      background: ${({ theme }) => theme.colors.variants.primaryDark1};
      box-shadow: none;
    }
    .leaflet-popup-close-button {
      display: none;
    }
  }

  @media (max-width: 767px) {
    width: 100%;
    padding-bottom: 66%;
    .leaflet-left {
      display: none;
    }
  }
`
