import styled from '@emotion/styled'
import { Checkbox } from 'app/components/Common/Checkbox'
import { FormMessages } from 'app/components/Common/FormMessages'
import { Input } from 'app/components/Common/Input'
import { Spinner } from 'app/components/Common/Spinner'
import { Textarea } from 'app/components/Common/Textarea'
import { FlexBox } from 'app/components/Layout/FlexBox'
import {
  ContactsFormSenderBackend,
  FormData,
} from 'app/utils/ContactsFormSender'
import { useVocabularyData } from 'app/utils/vocabulary'
import { isLeft } from 'fp-ts/lib/These'
import React, { memo, useCallback, useEffect, useMemo, useState } from 'react'
import ReactGA from 'react-ga'
import { useForm } from 'react-hook-form'

const EMAIL_REGEX = /^([^@\s]+)@((?:[-a-z0-9]+\.)+[a-z]{2,})$/i

export interface Props {
  languageCode: string
  onContactsFormSendToBackend: ContactsFormSenderBackend
}

export const ContactsForm = memo(function ContactsForm({
  languageCode,
  onContactsFormSendToBackend,
}: Props) {
  const [isSendingForm, setIsSendingForm] = useState(false)
  const [displaySendErrorFeedback, setDisplaySendErrorFeedback] =
    useState(false)
  const [displaySendConfirmFeedback, setDisplaySendConfirmFeedback] =
    useState(false)

  const onValidSubmission = useCallback(
    async (data: FormData) => {
      if (isSendingForm) {
        return
      }

      setDisplaySendErrorFeedback(false)
      setDisplaySendConfirmFeedback(false)
      setIsSendingForm(true)
      const sendResult = await onContactsFormSendToBackend(data)
      setIsSendingForm(false)

      if (isLeft(sendResult)) {
        setDisplaySendErrorFeedback(true)
      } else {
        setDisplaySendConfirmFeedback(true)
        ReactGA.event({
          category: 'Website',
          action: 'Submit',
          label: 'Contacts Form',
        })
      }
    },
    [isSendingForm, onContactsFormSendToBackend],
  )

  const { register, formState, handleSubmit } = useForm<FormData>({
    mode: 'onBlur',
    shouldFocusError: true,
    defaultValues: {
      offer_title:
        typeof window !== 'undefined'
          ? new URLSearchParams(window.location.search).get('offer') ||
            undefined
          : undefined,
    },
  })

  const onSubmit = useMemo(
    () => handleSubmit(onValidSubmission),
    [handleSubmit, onValidSubmission],
  )

  /**
   * Handle scrolling to first field with errors
   */
  useEffect(() => {
    if (formState.errors) {
      const firstErrorElement = Object.values(formState.errors).find(
        (fieldError) => fieldError?.ref !== undefined,
      )?.ref as HTMLElement | undefined

      if (firstErrorElement) {
        firstErrorElement.scrollIntoView({
          behavior: 'smooth',
          block: 'center',
        })
      }
    }
  }, [formState.errors])

  return (
    <Container dial={2}>
      <Form
        onSubmit={isSendingForm ? undefined : onSubmit}
        noValidate
        autoComplete={'off'}
      >
        <Spinner className={isSendingForm ? '' : 'hidden'} />
        <Title>{useVocabularyData('request-info', languageCode)}</Title>
        <Wrapper row wrap space="between">
          <Field>
            <Label>{`${useVocabularyData('name', languageCode)} *`}</Label>
            <Input
              error={formState.errors.name !== undefined}
              isDirty={formState.dirtyFields.name}
              type="text"
              required={true}
              {...register('name', {
                required: true,
                validate: (name) => name.length > 2,
              })}
            />
          </Field>
          <Field>
            <Label>{`${useVocabularyData('lastname', languageCode)} *`}</Label>
            <Input
              error={formState.errors.lastname !== undefined}
              type="text"
              required={true}
              {...register('lastname', {
                required: true,
                validate: (lastname) => lastname.length > 2,
              })}
            />
          </Field>
          <Field>
            <Label>{`${useVocabularyData('email', languageCode)} *`}</Label>
            <Input
              error={formState.errors.email !== undefined}
              isDirty={formState.dirtyFields.email}
              type="email"
              required={true}
              {...register('email', {
                required: true,
                validate: (email) => EMAIL_REGEX.test(email),
              })}
            />
          </Field>
          <Field>
            <Label>{useVocabularyData('telephone', languageCode)}</Label>
            <Input type="text" {...register('phone')} />
          </Field>
          <Field className="full-width">
            <Label>{useVocabularyData('message', languageCode)}</Label>
            <Textarea required={false} {...register('message')} />
          </Field>
          <Checkbox
            error={formState.errors.privacy_policy !== undefined}
            label={useVocabularyData('privacy-policy-text', languageCode)}
            required={true}
            {...register('privacy_policy', {
              required: true,
            })}
          />
          {displaySendErrorFeedback ? (
            <FormMessages
              text={useVocabularyData('form-error-message', languageCode)}
              title={useVocabularyData(
                'form-error-message-title',
                languageCode,
              )}
              type="error"
            />
          ) : null}
          {displaySendConfirmFeedback ? (
            <FormMessages
              text={useVocabularyData(
                'form-confirmation-message',
                languageCode,
              )}
              title={useVocabularyData(
                'form-confirmation-message-title',
                languageCode,
              )}
            />
          ) : null}
          <Input type="hidden" {...register('offer_title')} />
          <Input
            name="submit"
            type="submit"
            value={useVocabularyData('submit', languageCode)}
            variant="submit"
          />
        </Wrapper>
      </Form>
    </Container>
  )
})

const Container = styled(FlexBox)`
  margin: 4.875rem auto 9.25rem;
  padding: 0 1.5rem;

  @media (max-width: 1199px) {
    margin-bottom: 7.5rem;
  }

  @media (max-width: 767px) {
    margin: 3.75rem auto 5.25rem;
    padding: 0;
  }
`

const Form = styled.form`
  width: 100%;
  max-width: 58.625rem;
  background: ${({ theme }) => theme.colors.variants.neutralLight3};
  padding: 3.75rem;
  position: relative;

  @media (max-width: 767px) {
    padding: 3rem 1.5rem;
  }
`

const Title = styled.h2`
  color: ${({ theme }) => theme.colors.variants.neutralDark1};
  font-family: ${({ theme }) => theme.fontFamily.heading};
  font-size: 1.5rem;
  line-height: 2rem;
  text-align: center;
`

const Wrapper = styled(FlexBox)`
  max-width: 41.5625rem;
  margin: auto;
`

const Field = styled.div`
  width: calc(50% - 1.5rem);
  margin-top: 3rem;
  &.full-width {
    width: 100%;
  }

  @media (max-width: 374px) {
    width: calc(50% - 0.75rem);
  }
`

const Label = styled.label`
  color: ${({ theme }) => theme.colors.variants.neutralDark1};
  font-family: ${({ theme }) => theme.fontFamily.paragraph};
  font-size: 0.6875rem;
  font-weight: 500;
  line-height: 0.875rem;
  letter-spacing: 1px;
  text-transform: uppercase;
`
