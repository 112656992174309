import styled from '@emotion/styled'
import { FadeInOutAnimation } from 'app/components/Common/FadeInOutAnimation'
import { FlexBox } from 'app/components/Layout/FlexBox'
import React, { memo } from 'react'

export interface Props {
  images?: string[]
}

export const Hashtag = memo(function Hashtag({ images }: Props) {
  if (!images) {
    return null
  }

  return (
    <Container>
      <FadeInOutAnimation>
        <Title>#hotelortodemedici</Title>
      </FadeInOutAnimation>
      <FadeInOutAnimation>
        <Wrapper row space="between">
          {images.slice(0, 6).map((item, index) => (
            <Image key={index} style={{ backgroundImage: `url(${item})` }} />
          ))}
        </Wrapper>
      </FadeInOutAnimation>
    </Container>
  )
})

const Container = styled.section`
  margin-top: 5.25rem;
`

const Title = styled.h2`
  display: flex;
  align-items: center;
  flex-direction: row;
  color: ${({ theme }) => theme.colors.variants.neutralDark1};
  font-family: ${({ theme }) => theme.fontFamily.heading};
  font-size: 2.625rem;
  line-height: 3.375rem;
  margin-bottom: 1.5rem;
  &:before,
  &:after {
    content: '';
    flex: 1 1;
    background: ${({ theme }) => theme.colors.variants.neutralLight1};
    height: 2px;
  }
  &:before {
    margin-right: 1.5rem;
  }
  &:after {
    margin-left: 1.5rem;
  }
`

const Wrapper = styled(FlexBox)`
  margin: 3.75rem 0 2px;
`

const Image = styled.div`
  width: calc(16.667% - 2px);
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  padding-bottom: calc(16.667% - 2px);
`
