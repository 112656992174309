import { Hashtag as HashtagComponent } from 'app/components/Hashtag'
import { graphql, StaticQuery } from 'gatsby'
import React, { memo } from 'react'

import { getHashtagProps } from './getHashtagProps'

export const Hashtag = memo(function Hashtag() {
  return (
    <StaticQuery
      query={graphql`
        query HashtagData {
          cms {
            hashtag {
              images {
                directus_files_id {
                  id
                  file {
                    id
                    childImageSharp {
                      gatsbyImageData(layout: FIXED, width: 350)
                    }
                  }
                }
              }
            }
          }
        }
      `}
      render={(data) => {
        const props = getHashtagProps(data)

        return props ? <HashtagComponent {...props} /> : null
      }}
    />
  )
})
