import styled from '@emotion/styled'
import { FadeInOutAnimation } from 'app/components/Common/FadeInOutAnimation'
import { FlexBox } from 'app/components/Layout/FlexBox'
import React, { memo } from 'react'
import * as Icon from 'react-feather'

export interface Props {
  address?: string
  country?: string
  logo?: { [key: string]: any }
  postalCode?: string
  locality?: string
  siteName: string
  stars?: number
}

export const ContactsIntro = memo(function ContactsIntro({
  address,
  country,
  logo,
  postalCode,
  locality,
  siteName,
  stars,
}: Props) {
  const starsField: JSX.Element[] = []

  if (stars) {
    for (let i = 1; i <= stars; i++) {
      starsField.push(<Icon.Star key={i} />)
    }
  }

  return (
    <Container>
      <Wrapper>
        {logo ? (
          <FadeInOutAnimation>
            <Logo
              src={logo.src}
              alt={logo.alt}
              width={logo.width}
              height={logo.height}
            />
          </FadeInOutAnimation>
        ) : null}
        <FadeInOutAnimation>
          <SiteName>{siteName}</SiteName>
        </FadeInOutAnimation>
        {stars ? (
          <FadeInOutAnimation>
            <Stars dial={2} row>
              {starsField}
            </Stars>
          </FadeInOutAnimation>
        ) : null}
        {address ? (
          <FadeInOutAnimation>
            <Address>{`${address} - ${postalCode} ${locality} ${country}`}</Address>
          </FadeInOutAnimation>
        ) : null}
      </Wrapper>
    </Container>
  )
})

const Container = styled.section`
  margin: 5.25rem auto 0;
  text-align: center;
`

const Wrapper = styled.div`
  max-width: 45rem;
  margin: auto;
  padding: 0 1.5rem;
`

const Logo = styled.img`
  filter: grayscale(1);
  margin: 0 auto 1.5625rem;
  opacity: 0.2;
`

const SiteName = styled.h2`
  color: ${({ theme }) => theme.colors.variants.neutralDark2};
  font-family: ${({ theme }) => theme.fontFamily.heading};
  font-size: 2.625rem;
  line-height: 3.375rem;
  margin: 0 auto 0.5625rem;
`

const Stars = styled(FlexBox)`
  margin-bottom: 2.25rem;
  svg {
    height: 15px;
    fill: ${({ theme }) => theme.colors.variants.primaryDark1};
    stroke: none;
    margin: 0 2px;
  }
`

const Address = styled.div`
  color: ${({ theme }) => theme.colors.variants.neutralDark3};
  font-family: ${({ theme }) => theme.fontFamily.paragraph};
  font-size: 0.9375rem;
  line-height: 1.875rem;
`
