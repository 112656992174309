import styled from '@emotion/styled'
import { FlexBox } from 'app/components/Layout/FlexBox'
import React, {
  ChangeEventHandler,
  FocusEventHandler,
  forwardRef,
  memo,
} from 'react'

export interface Props {
  error?: boolean
  name: string
  label?: string
  required?: boolean
  onBlur?: FocusEventHandler<HTMLInputElement>
  onChange?: ChangeEventHandler<HTMLInputElement>
  onFocus?: FocusEventHandler<HTMLInputElement>
}

export const Checkbox = memo(
  forwardRef<HTMLInputElement, Props>(function Checkbox(
    { error = false, name, label, required, onBlur, onChange, onFocus },
    ref,
  ) {
    const id = `#form-${name}`
    return (
      <Container dial={4} row>
        <InputField
          ref={ref}
          id={id}
          name={name}
          type="checkbox"
          required={required}
          onBlur={onBlur}
          onChange={onChange}
          onFocus={onFocus}
        />
        {label ? (
          <Label
            error={error}
            htmlFor={id}
            dangerouslySetInnerHTML={{ __html: label }}
          />
        ) : null}
      </Container>
    )
  }),
)
Checkbox.displayName = 'Checkbox'

const Container = styled(FlexBox)`
  width: 100%;
  font-family: ${({ theme }) => theme.fontFamily.paragraph};
  font-size: 0.6875rem;
  color: ${({ theme }) => theme.colors.variants.neutralDark3};
  letter-spacing: 0.5px;
  cursor: pointer;
  margin-top: 2.5rem;
  position: relative;
`

const InputField = styled.input`
  opacity: 0;
  position: absolute;
  z-index: -1;

  &:checked ~ label {
    &:before {
      border-color: ${({ theme }) => theme.colors.variants.neutralDark1};
    }
    &:after {
      opacity: 1;
    }
  }
`

const Label = styled.label<{ error: boolean }>`
  padding-left: 20px;
  &:before {
    content: '';
    display: block;
    width: 14px;
    height: 14px;
    border: 2px solid
      ${({ error, theme }) =>
        error
          ? theme.colors.variants.dangerLight1
          : theme.colors.variants.neutralDark1};
    border-radius: 50%;
    position: absolute;
    top: -1px;
    left: 0;
  }
  &:after {
    content: '';
    width: 6px;
    height: 6px;
    background: ${({ theme }) => theme.colors.variants.neutralDark1};
    border-radius: 50%;
    opacity: 0;
    position: absolute;
    top: 3px;
    left: 4px;
  }

  a {
    font-weight: 700;
    text-decoration: underline;
  }
`
