import styled from '@emotion/styled'
import React, { memo } from 'react'
import Fade from 'react-reveal/Fade'

var QRCode = require('qrcode.react')

export interface Props {
  file?: string
  title?: string
  URL?: string
}

export const QRCodeGenerator = memo(function QRCodeGenerator({
  file,
  title,
  URL,
}: Props) {
  return (
    <Container>
      {title ? (
        <Fade>
          <Title>{title}</Title>
        </Fade>
      ) : null}
      {file ? <Code value={file} /> : null}
      {URL ? <Code value={URL} /> : null}
    </Container>
  )
})

const Container = styled.section`
  margin: 9.75rem 0;
  text-align: center;

  @media (max-width: 1199px) {
    margin: 7.5rem 0;
  }

  @media (max-width: 767px) {
    margin: 5.25rem 0 6.25rem;
  }
`

const Title = styled.h3`
  display: flex;
  align-items: center;
  flex-direction: row;
  color: ${({ theme }) => theme.colors.variants.neutralDark1};
  font-family: ${({ theme }) => theme.fontFamily.heading};
  font-size: 2.625rem;
  line-height: 3.375rem;
  margin-bottom: 1.5rem;
  &:before,
  &:after {
    content: '';
    flex: 1 1;
    background: ${({ theme }) => theme.colors.variants.neutralLight1};
    height: 2px;
  }
  &:before {
    margin-right: 1.5rem;
  }
  &:after {
    margin-left: 1.5rem;
  }
`

const Code = styled(QRCode)`
  margin: 1.5rem;
`
