import { Props as HashtagProps } from 'app/components/Hashtag'
import { HashtagDataQuery } from 'graphql-types'
import { compact } from 'lodash'

export const getHashtagProps = (
  data: HashtagDataQuery,
): HashtagProps | undefined => {
  const images = compact(data.cms.hashtag?.images).map(
    ({ directus_files_id }) =>
      directus_files_id?.file?.childImageSharp?.gatsbyImageData.images.fallback
        ?.src,
  )

  return {
    images: compact(images),
  }
}
